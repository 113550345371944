import React, { useState } from "react";
import { addDefaultImageSrc } from "../../../../utils/renderUtils/renderUtil";
import NO_IMAGE from "../../../../assets/images/image-coming-soon.jpg";
import {
  LabelPrimaryColor,
  SecondaryButton,
  TextFormating,
  TypographyFontColor,
  TypographyPrimaryBgColorDimmed,
  TypographyTextColor,
} from "../../UiDesign";
import RecentSearchTab from "../RecentSearchTab";
const SearchPreviewMob = (props) => {
  const { productDetails, chack_header_is_case, extraCharges } = props;
  const [restrictByOnhand, setrestrictByOnhand] = useState(
    JSON.parse(localStorage.getItem("Store_address"))?.restrict_by_onhand
  );
  return (
    <div className="searchMobile">
      {!props.productSearchLoaer &&
      productDetails.length === 0 &&
      (props.searchTextValue === "" || props.categoryIds === "") ? (
        <div>
          <RecentSearchTab
            searchProductByName={(val) => props.searchProductByName(val)}
          />
        </div>
      ) : (
        ""
      )}
      <ul>
        {productDetails && productDetails.length > 0 ? (
          productDetails.map((row, index) => {
            var check_is_case = chack_header_is_case[row.id]
              ? chack_header_is_case[row.id]
              : false;
            var check_added_qty_is_case =
              row?.added_quantity_is_case.indexOf(check_is_case);
            var check_is_case_flag = false;
            if (check_added_qty_is_case > -1) {
              check_is_case_flag = true;
            } else {
              check_is_case_flag = true;
              row.added_quantity_is_case.push(check_is_case);
              row.added_quantity.push(0);
              check_added_qty_is_case =
                row.added_quantity_is_case.indexOf(check_is_case);
            }
            return (
              <li key={index}>
                <div className="row">
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    <img
                      src={row.main_image ? row.main_image : NO_IMAGE}
                      onError={(e) => addDefaultImageSrc(e)}
                      alt="Search-Imag"
                      onClick={() =>
                        props.reDirectToDetailsPage(
                          row.id,
                          row.name,
                          row.slug,
                          row.brand_id,
                          row.category_id,
                          true
                        )
                      }
                    />
                    {extraCharges?.is_show_out_of_stock === true &&
                    row.out_of_stock === true ? (
                      <div className="out-of-stock">
                        <LabelPrimaryColor
                          className="text"
                          border={1}
                          component={"span"}
                        >
                          Out Of Stock
                        </LabelPrimaryColor>
                      </div>
                    ) : row.out_of_stock_case === true &&
                      row.added_quantity_is_case[check_added_qty_is_case] ===
                        true ? (
                      <div className="out-of-stock">
                        <LabelPrimaryColor
                          className="text"
                          border={1}
                          component={"span"}
                        >
                          Out Of Stock
                        </LabelPrimaryColor>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-7" style={{ fontSize: "15px" }}>
                    <TextFormating
                      Color="product"
                      component="span"
                      onClick={() =>
                        props.reDirectToDetailsPage(
                          row.id,
                          row.name,
                          row.slug,
                          row.brand_id,
                          row.category_id,
                          true
                        )
                      }
                    >
                      {row.name}
                    </TextFormating>
                    <h6>
                      {row?.original_price !== 0 ? (
                        <TypographyTextColor
                          style={{ minHeight: "35px" }}
                          Color="other"
                          className={
                            row?.is_by_weight === true &&
                            row?.estimate_weight_per_piece > 0
                              ? "details_pricerow_height"
                              : ""
                          }
                          onClick={() =>
                            props.reDirectToDetailsPage(
                              row.id,
                              row.name,
                              row.slug,
                              row.brand_id,
                              row.category_id,
                              true
                            )
                          }
                        >
                          {row?.is_case_check === true
                            ? "$" + Number(row?.case_price).toFixed(2)
                            : row?.is_special === true
                            ? row?.special_quantity > 0
                              ? row?.special_quantity +
                                "/$" +
                                Number(row?.special_price).toFixed(2)
                              : "$" + Number(row?.special_price).toFixed(2)
                            : "$" + Number(row?.today_price).toFixed(2)}{" "}
                          {row?.is_special === true ? (
                            <del
                              className="del_class"
                              style={{ marginLeft: "1rem" }}
                            >
                              {"$" + Number(row?.today_price).toFixed(2)}
                            </del>
                          ) : (
                            ""
                          )}
                          {row &&
                          row?.is_by_weight === true &&
                          row?.is_case_check === false
                            ? "/ LB"
                            : ""}
                          <h6>
                            <h6>
                              {row?.is_case_check
                                ? "($" +
                                  (
                                    row?.case_price / row?.case_quantity
                                  ).toFixed(2)
                                : ""}
                              {row?.is_by_weight === true && row?.is_case_check
                                ? "/ LB each)"
                                : row?.is_by_weight === false &&
                                  row?.is_case_check
                                ? " each)"
                                : ""}{" "}
                            </h6>
                          </h6>
                          {row?.is_offer ? (
                            <>
                              <del className="del_class">
                                {row?.is_offer ? row?.unit : "$"}
                                {row?.is_offer
                                  ? row?.added_quantity_is_case[
                                      this.state.pro_details_ind
                                    ] === true
                                    ? "$" +
                                      Number(
                                        row?.case_quantity * row?.today_price
                                      ).toFixed(2)
                                    : "$" +
                                      Number(row?.original_price).toFixed(2)
                                  : ""}
                              </del>
                              <span className="price_off">
                                {row?.is_offer ? row?.offer_tag + "%" : ""}
                                {row &&
                                row?.special_quantity > 0 &&
                                row?.special_price > 0 &&
                                row?.offer_msg === "B"
                                  ? " (Price $ " +
                                    (
                                      row?.special_price / row?.special_quantity
                                    ).toFixed(2) +
                                    " applied at Minimum 1 quantity"
                                  : row &&
                                    row?.special_quantity > 0 &&
                                    row?.special_price > 0 &&
                                    row?.offer_msg === "M"
                                  ? "(Price $ " +
                                    row?.special_price.toFixed(2) +
                                    " applied at Minimum " +
                                    row?.special_quantity +
                                    " quantity)"
                                  : row &&
                                    row?.special_quantity > 0 &&
                                    row?.special_price > 0 &&
                                    row?.offer_msg === "P"
                                  ? "(Price $" +
                                    (
                                      row?.special_price / row?.special_quantity
                                    ).toFixed(2) +
                                    " applied at the Pack of " +
                                    row?.special_quantity +
                                    " quantity and Maximum " +
                                    row?.special_max +
                                    " pack)"
                                  : ""}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </TypographyTextColor>
                      ) : (
                        ""
                      )}
                    </h6>
                    {row?.is_case === true ? (
                      <TypographyPrimaryBgColorDimmed
                        onClick={() =>
                          props.ProductCaseFunctionlity(
                            row?.id,
                            !row?.is_case_check,
                            row
                          )
                        }
                        component={"div"}
                        border={1}
                        color="primary"
                        role="button"
                        className="case-cnt mt-1 d-flex"
                        style={{
                          marginTop: 0,
                          left: "auto",
                          bottom: "4px",
                          width: "auto",
                          zIndex: 9999,
                          right: 0,
                        }}
                      >
                        <TypographyFontColor component={"span"} border={0}>
                          <input
                            type="checkbox"
                            name="vehicle1"
                            checked={row?.is_case_check === true ? true : false}
                            style={{
                              flexBasis: "20%",
                              transform:'scale(1.3)'
                            }}
                          />
                          <label for="vehicle1" className="ml-1">
                            Case of
                            <span className="ml-1">{row?.case_quantity}</span>
                          </label>
                        </TypographyFontColor>
                      </TypographyPrimaryBgColorDimmed>
                    ) : (
                      ""
                    )}
                  </div>
                  {extraCharges?.is_show_out_of_stock === true &&
                  row.out_of_stock === true &&
                  restrictByOnhand === true ? (
                    ""
                  ) : (
                    <div className="col-3">
                      {row.added_quantity[check_added_qty_is_case] === 0 &&
                      row.is_case === false ? (
                        <SecondaryButton
                          onClick={() =>
                            props.openAddCount(
                              row.id,
                              row.added_quantity[check_added_qty_is_case] + 1,
                              row.added_quantity_is_case[
                                check_added_qty_is_case
                              ],
                              row.added_quantity[check_added_qty_is_case]
                            )
                          }
                          style={{ color: "#fff", marginTop: "18px" }}
                        >
                          Add
                        </SecondaryButton>
                      ) : (
                        <div style={{ padding: "5px 0" }}>
                          <SecondaryButton
                            onClick={() =>
                              props.openAddCount(
                                row.id,
                                row.added_quantity[check_added_qty_is_case] + 1,
                                row.added_quantity_is_case[
                                  check_added_qty_is_case
                                ],
                                row.added_quantity[check_added_qty_is_case]
                              )
                            }
                            style={{ color: "#fff" }}
                          >
                            {row.disabled ? (
                              "..."
                            ) : (
                              <span className="icon-plus"></span>
                            )}
                          </SecondaryButton>
                          <div
                            style={{
                              color: "black",
                              background: "#fff",

                              textAlign: "center",
                            }}
                          >
                            {row.added_quantity[check_added_qty_is_case] > 0
                              ? row.added_quantity[check_added_qty_is_case]
                              : 0}
                          </div>
                          <SecondaryButton
                            onClick={() =>
                              props.openAddCount(
                                row.id,
                                row.added_quantity[check_added_qty_is_case] - 1,
                                row.added_quantity_is_case[
                                  check_added_qty_is_case
                                ],
                                row.added_quantity[check_added_qty_is_case]
                              )
                            }
                            style={{ color: "#fff" }}
                          >
                            {row.disabled ? (
                              "..."
                            ) : (
                              <span className="icon-minus"></span>
                            )}
                          </SecondaryButton>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
            );
          })
        ) : (
          <div className="try_with_other_word">
            <span>
              <TypographyFontColor border={0}>
                {props.searchResultMsg}
              </TypographyFontColor>
            </span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default SearchPreviewMob;
