import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";
import React from "react";
import {
  addDefaultImageSrc,
  convertTodate,
} from "../../../../utils/renderUtils/renderUtil";
import {
  LabelPrimaryColor,
  TypographyFontColor,
  TypographySecondaryBgColor,
  TypographyTextColor,
  TypographyTextFontFamily,
} from "../../UiDesign";
import LoderImage from "../../../../assets/images/more.png";
import NO_IMAGE from "../../../../assets/images/image-coming-soon.jpg";
import { AiOutlineFieldTime, AiFillEdit } from "react-icons/ai";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MdDelete } from "react-icons/md";
import EBTLOGO from "../../../../assets/images/logo-snap.png";
const CartListing = (props) => {
  const {
    cartCategoryList,
    Inactiveproduct,
    self,
    CardOrderSummary,
    TotalPayment,
    orderSummeryLoader,
    ActiveSlotData,
    extraCharges,
    tipForPacker,
    donation,
    tipForDeliveryboy,
    MobileViewOrdersummary,
    selectedAddress,
    DeleteCartItem,
    isShowEBT,
  } = props;
  return (
    <>
      {MobileViewOrdersummary === false ? (
        <div className="cart-listing-details">
          {cartCategoryList && cartCategoryList.length > 0 ? (
            cartCategoryList.map((row, index) => {
              return (
                (
                  <div className="cart_head">
                    <h5>{row.category_name}</h5>
                  </div>
                ),
                row.items && row.items.length > 0
                  ? row.items.map(function (item, key) {
                      return (
                        <div className="cart_sub_details">
                          <div className="cart_details">
                            {/* <IconButton
                              onClick={() => {
                                DeleteCartItem(
                                  item.product_id,
                                  item.is_case,
                                  item.id,
                                  item.container_type
                                );
                              }}
                            >
                              {" "}
                              <MdDelete style={{ color: "red" }} />
                            </IconButton> */}
                            <div
                              className={
                                (extraCharges?.is_show_out_of_stock === true &&
                                  item.out_of_stock === true) ||
                                (Inactiveproduct &&
                                  Inactiveproduct.length > 0 &&
                                  Inactiveproduct.includes(item.id))
                                  ? "smll_img out_of_stock_content"
                                  : "smll_img"
                              }
                              style={{
                                position:
                                  item.out_of_stock === true ? "relative" : "",
                              }}
                            >
                              <img
                                onError={(e) => addDefaultImageSrc(e)}
                                className=""
                                src={item.image ? item.image : NO_IMAGE}
                                alt=""
                                style={{
                                  filter:
                                    extraCharges?.is_show_out_of_stock ===
                                      true && item.out_of_stock === true
                                      ? "blur(5px)"
                                      : "none",
                                }}
                              />

                              {extraCharges?.is_show_out_of_stock === true &&
                              item.out_of_stock === true ? (
                                <div className="out-of-stock">
                                  <LabelPrimaryColor
                                    component={"span"}
                                    border={2}
                                    className="text"
                                  >
                                    Out Of Stock
                                  </LabelPrimaryColor>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="cart_desc">
                              <div className="cart_desc_name">
                                <TypographyTextColor
                                  component="p"
                                  Color="product"
                                >
                                  {item.product_name}
                                </TypographyTextColor>
                                {Inactiveproduct &&
                                Inactiveproduct.length > 0 &&
                                Inactiveproduct.includes(item.id) ? (
                                  <span className="ProductNotavail_zipTitle ml-1">
                                    * This item is not available.
                                  </span>
                                ) : (
                                  ""
                                )}
                                {item.note === "" || item.note === null ? (
                                  <></>
                                ) : (
                                  <div className="note_box">
                                    {" "}
                                    <p className="note">
                                      {item.note.split("~")[0] === ""
                                        ? item.note.split("~")[1]
                                        : item.note.split("~").join(", ")}
                                    </p>
                                  </div>
                                )}
                                {row.estimate_weight_per_piece > 0
                                  ? row.estimate_weight_per_piece + " LB | "
                                  : ""}{" "}
                                {row.is_by_weight === true ? "LB | " : ""}
                              </div>

                              <div>
                                <span className="prod_pieces mr-2">
                                  {item.discount > 0 ? item.unit : ""}
                                  {item.discount > 0
                                    ? Number(item.original_unit_price).toFixed(
                                        2
                                      )
                                    : ""}
                                </span>
                                <TypographyTextColor
                                  component="span"
                                  Color="other"
                                  className="prod_cost"
                                >
                                  ${Number(item.total).toFixed(2)}
                                </TypographyTextColor>
                                {item.is_ebt&&JSON.parse(localStorage.getItem("Store_address"))?.is_show_ebt ? (
                                  <img
                                    src={EBTLOGO}
                                    style={{
                                      width: "35px",
                                      padding: "5px",
                                    }}
                                    alt="ebtlogo"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <TypographySecondaryBgColor className="cart-count_box">
                              <button
                                className={"count_btn count" + item.id}
                                value={item.id}
                                entry={index}
                                onClick={(e) =>
                                  self.increaseItemCount(
                                    e.currentTarget.value,
                                    item.product_id,
                                    item.category_id,
                                    item.is_case,
                                    item.id,
                                    item.note,
                                    item.container_type
                                  )
                                }
                                disabled={item.disabled}
                              >
                                {item.disabled ? (
                                  <img src={LoderImage} alt="" />
                                ) : (
                                  <span
                                    className="icon-plus"
                                    key={item.id}
                                  ></span>
                                )}
                              </button>
                              <div className="count_no">
                                {item.qty > 0 ? item.qty : 0}
                              </div>
                              <button
                                className="count_btn"
                                onClick={(e) =>
                                  self.countItemDecrease(
                                    e.currentTarget.value,
                                    item.product_id,
                                    item.category_id,
                                    item.is_case,
                                    item.id,
                                    item.note,
                                    item.container_type
                                  )
                                }
                                disabled={item.disabled}
                              >
                                {item.disabled ? (
                                  <img src={LoderImage} alt="" />
                                ) : (
                                  <span className="icon-minus"></span>
                                )}
                              </button>
                            </TypographySecondaryBgColor>
                          </div>
                          <Divider />
                        </div>
                      );
                    })
                  : ""
              );
            })
          ) : (
            <div className="emtpyCartMsg">There are no items!</div>
          )}
          {/* <div className="cart_items_total">
                        <div className="sub_total">
                          <div className="sub_desc">Subtotal</div>
                          <div className="sub_total_count">$60.00</div>
                        </div>
                        <div className="cart_checkout">
                          <div className="bttn-fill">
                            <Link to className="bttn_grn">
                              Checkout
                            </Link>
                          </div>
                        </div>
                      </div> */}
        </div>
      ) : (
        ""
      )}
      {window.screen.availWidth <= 700 && MobileViewOrdersummary ? (
        <>
          {" "}
          <div className="cart_order_summary">
            <div className="order_summ d-flex">
              <ChevronRightIcon
                onClick={() => {
                  props.BacktoListing();
                }}
                style={{
                  transform: " rotate(180deg)",
                  fontSize: "2.5rem",
                }}
              />
              <TypographyTextColor
                Color="heading"
                component="h5"
                style={{ width: "100%" }}
              >
                Order Summary
              </TypographyTextColor>
            </div>
            <div className="summary_details">
              {orderSummeryLoader ? (
                <div className="loader_wrapper">
                  <CircularProgress
                    style={{
                      display: "flex",
                      color: "#3d9b7b",
                      position: "relative",
                      "& > * + *": {
                        marginLeft: 2,
                      },
                    }}
                  />
                </div>
              ) : (
                <>
                  <ul>
                    <li>
                      <div className="summ_det_desc">
                        <TypographyTextColor Color="heading" component="span">
                          Subtotal
                        </TypographyTextColor>
                      </div>
                      <div className="summ_rate">
                        <TypographyTextColor Color="other" component="span">
                          {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                          {CardOrderSummary && CardOrderSummary.subtotal > 0
                            ? Number(CardOrderSummary.subtotal).toFixed(2)
                            : "0.00"}
                        </TypographyTextColor>
                      </div>
                    </li>
                    {isShowEBT && CardOrderSummary?.ebt_subtotal > 0 ? (
                      <li style={{ fontSize: "11px" }}>
                        <div className="summ_det_desc">
                          <TypographyTextColor
                            Color="heading"
                            component="span"
                            style={{ textTransform: "capitalize" }}
                          >
                            SNAP Eligible
                          </TypographyTextColor>
                        </div>
                        <div className="summ_rate">
                          <TypographyTextColor Color="other" component="span">
                            {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                            {CardOrderSummary &&
                            CardOrderSummary.ebt_subtotal > 0
                              ? Number(CardOrderSummary.ebt_subtotal).toFixed(2)
                              : "0.00"}
                          </TypographyTextColor>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}
                    {CardOrderSummary.discount === 0 ? (
                      <></>
                    ) : (
                      <li>
                        <div className="summ_det_desc">
                          <TypographyTextColor Color="heading" component="span">
                            Discount
                          </TypographyTextColor>
                        </div>
                        <div className="summ_rate">
                          <TypographyTextColor
                            Color="other"
                            component="span"
                            className="discount_rate"
                          >
                            {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                            {CardOrderSummary && CardOrderSummary.discount > 0
                              ? Number(CardOrderSummary.discount).toFixed(2)
                              : "0.00"}
                          </TypographyTextColor>
                        </div>
                      </li>
                    )}
                    {ActiveSlotData?.schedule === 3 &&
                    cartCategoryList.length > 0 ? (
                      <li>
                        <div className="summ_det_desc">
                          <TypographyTextColor Color="heading" component="span">
                            Shipping Charge
                          </TypographyTextColor>
                        </div>
                        <div className="summ_rate">
                          <TypographyTextColor Color="other" component="span">
                            {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                            {CardOrderSummary &&
                            CardOrderSummary.shipping_price > 0
                              ? Number(CardOrderSummary.shipping_price).toFixed(
                                  2
                                )
                              : "0.00"}
                          </TypographyTextColor>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}

                    <li>
                      <div className="summ_det_desc">
                        <TypographyTextColor Color="heading" component="span">
                          Estimated Tax
                        </TypographyTextColor>
                      </div>
                      <div className="summ_rate">
                        <TypographyTextColor Color="other" component="span">
                          {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                          {CardOrderSummary &&
                          CardOrderSummary.Estimated_tax > 0
                            ? Number(CardOrderSummary.Estimated_tax).toFixed(2)
                            : "0.00"}
                        </TypographyTextColor>
                      </div>
                    </li>
                    {CardOrderSummary.delivery_charge === 0 ? (
                      <></>
                    ) : (
                      <li>
                        <div className="summ_det_desc">
                          <TypographyTextColor Color="heading" component="span">
                            Delivery Charges
                          </TypographyTextColor>
                        </div>
                        <div className="summ_rate">
                          <TypographyTextColor Color="other" component="span">
                            {CardOrderSummary ? CardOrderSummary.unit : ""}
                            {CardOrderSummary
                              ? Number(
                                  CardOrderSummary.delivery_charge
                                ).toFixed(2)
                              : "0.00"}{" "}
                          </TypographyTextColor>
                        </div>
                      </li>
                    )}
                    {CardOrderSummary.extra_charges_name !== "" &&
                    CardOrderSummary.extra_charges_name !== null &&
                    CardOrderSummary.extra_charges_price !== 0 &&
                    cartCategoryList.length > 0 ? (
                      <li>
                        <div className="summ_det_desc">
                          <TypographyTextColor
                            Color="heading"
                            component="span"
                            style={{ textTransform: "capitalize" }}
                          >
                            {CardOrderSummary.extra_charges_name}
                          </TypographyTextColor>
                        </div>
                        <div className="summ_rate">
                          <TypographyTextColor Color="other" component="span">
                            {CardOrderSummary ? CardOrderSummary.unit : ""}{" "}
                            {CardOrderSummary &&
                            CardOrderSummary.extra_charges_price > 0
                              ? Number(
                                  CardOrderSummary.extra_charges_price
                                ).toFixed(2)
                              : "0.00"}
                          </TypographyTextColor>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                  {extraCharges.tip_for_packer === false &&
                  extraCharges.tip_for_delivery_boy === false &&
                  extraCharges.donation === false ? (
                    <></>
                  ) : (
                    <ul className="tip_don">
                      {extraCharges.tip_for_packer === true ? (
                        <li>
                          <div className="summ_det_desc">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              Tip for Packer
                            </TypographyTextColor>
                          </div>
                          <div className="summ_rate">
                            <TypographyTextColor
                              Color="other"
                              component="span"
                              className="dollar"
                            >
                              ${" "}
                            </TypographyTextColor>
                            <input
                              type="text"
                              value={tipForPacker}
                              onChange={(e) =>
                                props.onTipForPackerChange(e, e.target.value)
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                      {extraCharges.tip_for_delivery_boy === true ? (
                        <li>
                          <div className="summ_det_desc">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                            >
                              Delivery Tip
                            </TypographyTextColor>
                          </div>
                          <div className="summ_rate">
                            <TypographyTextColor
                              Color="other"
                              component="span"
                              className="dollar"
                            >
                              ${" "}
                            </TypographyTextColor>
                            <input
                              type="text"
                              value={tipForDeliveryboy}
                              onChange={(e) =>
                                props.onTipForDeliveryBoy(e, e.target.value)
                              }
                              onFocus={(e) => e.target.select()}
                              className="auto-fill"
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                      {extraCharges.donation === true ? (
                        <li>
                          <div className="summ_det_desc">
                            <TypographyTextColor
                              Color="heading"
                              component="span"
                              className="d-flex align-items-center"
                            >
                              Donation(
                              <a
                                href={extraCharges.donation_url}
                                target="_blank"
                              >
                                <TypographyFontColor
                                  border={0}
                                  component={"span"}
                                  className="change-add"
                                >
                                  {" "}
                                  {extraCharges.donation_name}
                                </TypographyFontColor>
                              </a>
                              )
                            </TypographyTextColor>
                          </div>
                          <div className="summ_rate">
                            <TypographyTextColor
                              Color="other"
                              component="span"
                              className="dollar"
                            >
                              ${" "}
                            </TypographyTextColor>
                            <input
                              type="text"
                              value={donation}
                              onChange={(e) =>
                                props.onTipForDonation(e, e.target.value)
                              }
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  )}
                </>
              )}
            </div>

            <div className="summ_total">
              <div className="cart_total">
                <TypographyTextColor Color="heading" component="span">
                  Total
                </TypographyTextColor>
              </div>
              <div className="total_price">
                <TypographyTextColor Color="heading" component="span">
                  ${TotalPayment > 0 ? TotalPayment : "0.00"}
                </TypographyTextColor>
              </div>
            </div>
          </div>
          {localStorage.getItem("frontend_animation") !==
            "no_delivery_pickup" && cartCategoryList.length > 0 ? (
            <div
              className="cart_order_summary"
              style={{ marginTop: "13px", paddingTop: 0 }}
            >
              <div
                className={`px-2${
                  selectedAddress?.addressCount === 1 ? " py-2" : ""
                }`}
              >
                {" "}
                <TypographyTextColor
                  Color="heading"
                  style={{
                    fontWeight: "bolder",
                    fontSize: "17px",
                    letterSpacing: "1px",
                  }}
                  className="d-flex align-items-center justify-content-between"
                  component="span"
                >
                  {localStorage.getItem("slot") &&
                  JSON.parse(localStorage.getItem("slot"))?.schedule === 1
                    ? "Delivery "
                    : JSON.parse(localStorage.getItem("slot"))?.schedule === 2
                    ? "Pickup "
                    : "Shipping "}
                  address
                  {selectedAddress?.addressCount === 1 ? (
                    ""
                  ) : (
                    <IconButton onClick={() => props.openAddressPopupMobile()}>
                      <AiFillEdit />
                    </IconButton>
                  )}
                </TypographyTextColor>
                {selectedAddress?.pickup_type === "one_date_time" ? (
                  <div style={{ marginLeft: "-5px" }}>
                    <span>
                      <AiOutlineFieldTime style={{ color: "red" }} />
                    </span>
                    <span>
                      {convertTodate(selectedAddress?.pickup_date)}
                      &nbsp;( {selectedAddress?.pickup_time} )
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {selectedAddress ? (
                <div
                  className="m-auto"
                  style={{
                    padding: "8px 8px 20px 8px",
                    borderTop: "1px solid #dad6d6",
                  }}
                >
                  <TypographyTextColor Color="heading" component="b">
                    {selectedAddress.name
                      ? selectedAddress.name
                      : selectedAddress.first_name +
                        " " +
                        selectedAddress.last_name}
                  </TypographyTextColor>

                  <TypographyTextFontFamily component="div" type="other">
                    {selectedAddress?.address && selectedAddress?.address !== ""
                      ? selectedAddress.address
                      : selectedAddress.street_name !== "" &&
                        selectedAddress.street_name !== undefined
                      ? selectedAddress.street_name
                      : "" + " "}{" "}
                  </TypographyTextFontFamily>
                  <TypographyTextFontFamily
                    component="div"
                    type="other"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <span
                      style={{
                        display:
                          selectedAddress?.city_name !== null &&
                          selectedAddress?.city_name !== ""
                            ? "block"
                            : "none",
                      }}
                    >
                      {selectedAddress.city_name + ", "}{" "}
                    </span>
                    <span
                      style={{
                        display:
                          selectedAddress?.state_name !== null &&
                          selectedAddress?.state_name !== ""
                            ? "block"
                            : "none",
                      }}
                    >
                      {selectedAddress.state_name}
                    </span>
                    <span
                      style={{
                        display:
                          selectedAddress?.zipcode !== null &&
                          selectedAddress?.zipcode !== ""
                            ? "block"
                            : "none",
                      }}
                    >
                      {selectedAddress.zipcode}
                    </span>
                  </TypographyTextFontFamily>
                  <TypographyTextFontFamily component="div" type="other">
                    {selectedAddress?.pickup_message !== "" &&
                    selectedAddress?.pickup_message !== null &&
                    selectedAddress?.pickup_message !== "undefined" &&
                    selectedAddress?.pickup_message !== undefined ? (
                      <div
                        className="cust_home"
                        // style={{ maxWidth: "190px" }}
                      >
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title={selectedAddress.pickup_message}
                        >
                          Message:{" "}
                          {selectedAddress?.pickup_message.length > 50
                            ? selectedAddress?.pickup_message.slice(0, 50) +
                              "..."
                            : selectedAddress.pickup_message}{" "}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </TypographyTextFontFamily>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {window.screen.availWidth <= 700 && MobileViewOrdersummary === false ? (
        <div className="bttn-fill1 text-center mt-2 mb-2 d-flex justify-content-center continuebtn" style={{paddingBottom:'10rem'}}>
          <Button
            className="empty_Btnmob mr-1"
            onClick={() => props.handleOpnDialog()}
            variant="outlined"
            size="small"
          >
            <TypographyTextFontFamily component="span" type="other">
              {" "}
              Empty Cart
            </TypographyTextFontFamily>
          </Button>
          <Button
            onClick={() => props.redirectToListing()}
            variant={window.screen.availWidth <= 900 ? "outlined" : ""}
            size={window.screen.availWidth <= 900 ? "small" : ""}
          >
            <TypographyTextFontFamily component="span" type="other">
              {" "}
              Continue Shopping
            </TypographyTextFontFamily>
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CartListing;
