import React, { Component } from "react";
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";
import UserDetailsMenu from "./common/Header/userDetailsMenu";
import CategoriesModal from "./common/Category/categoriesModal";
import NO_IMAGE from "../assets/images/image-coming-soon.jpg";
import ApiUrls from "../utils/apiUtils/apiUrl";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  CategeriesListView,
  UpdateCartFunctionlity,
  SubCategeriesListView,
} from "../actions/home/homeAction";
import {
  favoriteLevelListFunctionlity,
  AddFavoriteAddToCartFunctionlity,
  RemoveFavouriteList,
  AddFavoriteLevelFunctionlity,
} from "../actions/favorite/favoriteAction";
import {
  CartListFunctionlity,
  DeleteCrtIemFunctionlity,
  GetExtraChargesData,
} from "../actions/cart/cartAction";
import Pagination from "react-js-pagination";
import { RefreshLogin } from "../actions/login/loginAction";
import { GetProfileDetails } from "../actions/profile/profileAction";
import queryString from "query-string";
import SubCategoryModal from "./common/Category/SubCategoryModal";

import {
  commonPageTitel,
  CommonSetParentOnlyIdLevel,
  CommonSetParentLevelSet,
  CommonSetParentIds,
  getSchedule_zip,
  sweetAlertBox,
  SinglecheckImageUrls,
  checkImageUrls,
} from "../utils/renderUtils/renderUtil";
import CustomPreloader from "./common/Loader/CustomPreloader";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  ScrollColorTypography,
  SecondaryButton,
  TypographySecondaryColor,
  TypographyTextColor,
} from "./common/UiDesign";
class FavoriteList extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    var page = 1;
    var limit = 10;
    this.state = {
      open: false,
      pageName: "favoritePage",
      isLogin: false,
      openCategory: false,
      CategoryDetails: [],
      CategoryOption: [],
      cartLoader: true,
      mobileViewHide: true,
      favouriteList: [],
      CartCount: [],
      cartListOpen: true,
      activePage: page,
      limit: limit,
      totalSize: 0,
      success: false,
      error: false,
      openDialog: false,
      favourite_id: "",
      favoriteCartItemList: [],
      SubTotal: 0,
      setOpen: false,
      favoriteLevelName: "",
      headerinputField: false,
      bodyInnerClass: false,
      logo: "",
      profileDetails: [],
      parent_Ids: [],
      parent_onlyid: [],
      parent_level: [],
      SubCategoryDetails: [],
      SubSubCategoryDetails: [],
      subCategoryLoader: false,
      open_inner: false,
      SubcategoryName: [],
      extraCharges: [],
      MainLoader: true,
      web_customize: JSON.parse(localStorage.getItem("web_customize")),
    };
    this.handleOpenDialogFav = this.handleOpenDialogFav.bind(this);
    this.handleCloseDialogFav = this.handleCloseDialogFav.bind(this);
    this.handleInnerCloseDialogFav = this.handleInnerCloseDialogFav.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.web_customize === null) {
      this.setState({
        web_customize: JSON.parse(localStorage.getItem("web_customize")),
      });
    }
  }
  handleOpenDialogFav() {
    this.setState({ open: true });
  }
  handleCloseDialogFav() {
    this.setState({ open: false });
  }

  categoryOpen() {
    this.setState({ openCategory: !this.state.openCategory });
  }

  FetchProfileDetailsFav = () => {
    let formValues = {};
    let profile_Details = [];
    GetProfileDetails(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.result) {
        profile_Details = res.data.result;
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchProfileDetailsFav();
            }
          );
        }
      }
      this.setState({ profileDetails: profile_Details });
    });
  };

  componentDidMount() {
    //;
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
    let logo = this.state.logo;
    const accessTokenForLogo = JSON.parse(localStorage.getItem("access_token"));
    logo = accessTokenForLogo ? accessTokenForLogo.logo : "";
    const accessToken = localStorage.getItem("access_token");
    const isLogin = localStorage.getItem("isLogin");
    if (accessToken && isLogin === "true") {
      this.FetchCategoryList();
      this.FetchCartItemList();
      this.FetchFavoriteLevelList(this.state.activePage, this.state.limit);
      this.FetchProfileDetailsFav();
      this.ExtraChargesFunctiolity();
    }
    this.setState({ logo: logo });
  }

  ExtraChargesFunctiolity = () => {
    let formValues = {};
    GetExtraChargesData(formValues, this.props.dispatch).then((res) => {
      let extraCharges = [];
      if (res.data && res.data.result) {
        extraCharges = res.data.result;
      }
      this.setState({ extraCharges: extraCharges });
    });
  };

  RefreshApiToken = (refresh_token, myThis, callback) => {
    let formValues = {
      refresh: refresh_token,
    };
    RefreshLogin(formValues).then((res) => {
      if (res.data) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        access_token.access_token = res.data.access_token;
        localStorage.setItem("access_token", JSON.stringify(access_token));
        callback(myThis);
      } else {
        this.logout();
      }
    });
  };
  openDetailsMenu = () => {
    this.setState({ mobileViewHide: false });
  };

  closeDetailsMenu = () => {
    this.setState({ mobileViewHide: true });
  };

  FetchFavoriteLevelList = async (activePage, limit) => {
    let formValues = {
      lookup: false,
      page: activePage,
      limit: limit,
      product_id: "",
    };
    await favoriteLevelListFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        let favouriteList = [];
        let totalSize = 0;
        if (res.data && res.data.result) {
          favouriteList = res.data.result;
          totalSize = res.data.total;
        } else if (
          res.errors &&
          res.errors._error &&
          res.errors._error.status === 401
        ) {
          const refreshToken = JSON.parse(localStorage.getItem("access_token"));
          let myThis = this;
          if (refreshToken) {
            this.RefreshApiToken(
              refreshToken.refresh_token,
              myThis,
              function (myThis) {
                myThis.FetchFavoriteLevelList(
                  formValues.page,
                  formValues.limit
                );
              }
            );
          }
        }
        this.setState({
          favouriteList: favouriteList,
          totalSize,
          activePage: activePage,
          MainLoader: false,
        });
      }
    );
  };

  logout = (e) => {
    localStorage.removeItem("access_token");
    localStorage.setItem("listOpen", false);
    localStorage.removeItem("email");
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("name");
    this.setState({ isLogin: false });
    this.setState({ listOpen: false });
    // this.HitGuestApiToken();

    this.props.history.push({
      pathname: "/",
    });
  };

  FetchCartItemList = async () => {
    let formValues = getSchedule_zip();
    let SubTotal = 0;
    this.setState({ cartLoader: true });
    await CartListFunctionlity(formValues, this.props.dispatch).then((res) => {
      let FinalCartCount = [];
      let favoriteCartItemList = [];

      if (res.errors && res.errors._error && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCartItemList();
            }
          );
        }
      }
      if (res.data && res.data.total > 0) {
        const respone = res.data.result;
          if (respone && respone.length > 0) {
            for (let i = 0; i < respone.length; i++) {
              favoriteCartItemList.push(respone[i])
              SubTotal += Number(respone[i].total_price);
            }
          }

          if (res.data.total > 0) {
            FinalCartCount.item = res.data.total;
            FinalCartCount.price = Number(res.data.total_subtotal).toFixed(2);
          }

          this.setState({
            cartListOpen: true,
            CartCount: FinalCartCount,
            cartLoader: false,
            favoriteCartItemList,
            SubTotal,
          });
        // });
      }
      this.setState({
        cartLoader: false,
      });
    });
  };

  FetchCategoryList = async () => {
    this.setState({
      categoryLoader: true,
    });
    let formValues = getSchedule_zip();
    await CategeriesListView(formValues, this.props.dispatch).then((res) => {
      let categoryList = [];
      let CategoryOption = [];
      if (res.data && res.data.result) {
        categoryList = res.data.result;
        categoryList.forEach((ele) => {
          let globalUrl = ele?.cloudinary_cat_images_global;

          if (ele.image === "" || ele.image === null) {
            SinglecheckImageUrls(globalUrl).then((res) => {
              if (res === true) {
                ele.image = ele?.cloudinary_cat_images_global;
                ele.valid = true;
              } else {
                ele.image = NO_IMAGE;
                ele.valid = false;
              }
            });
          } else {
            ele.image =
              ele.image[0] === "/"
                ? ApiUrls.BASH_URL + "/media" + ele.image
                : ele.image;
            ele.valid = false;
          }
        });
      } else if (
        res.errors &&
        res.errors._error &&
        res.errors._error.status === 401
      ) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchCategoryList();
            }
          );
        }
      }
      CategoryOption.push({ label: "All Categories", value: "" });
      for (let i = 0; i < categoryList.length; i++) {
        CategoryOption.push({
          label: categoryList[i].name,
          value: categoryList[i].id,
        });
      }
      this.setState({
        CategoryDetails: categoryList,
        categoryLoader: false,
        CategoryOption,
      });
    });
  };

  convertTodate = (value) => {
    if (value) {
      let date = new Date(value);
      return date.toDateString().split(" ").slice(1).join(" ");
    }
  };

  handlePageChange(pageNumber) {
    window.scrollTo(0, 0);
    this.FetchFavoriteLevelList(pageNumber, this.state.limit);
    var url = "?page=" + pageNumber + "&limit=" + this.state.limit;
    this.props.history.push(url);
  }

  renderShowsTotal() {
    let start =
      parseInt(this.state.activePage - 1, 10) * parseInt(this.state.limit, 10);
    let from = this.state.totalSize > 0 ? start + 1 : 0;
    let to = start + parseInt(this.state.limit);
    to =
      parseInt(this.state.totalSize, 10) > to
        ? to
        : parseInt(this.state.totalSize, 10);
    return (
      <p>
        Showing {from} to {to} of {this.state.totalSize} results
      </p>
    );
  }

  addFavoiteAddToCartFunctionlity = (favoritelabel_id, create_new_cart) => {
    let formValues = {
      favoritelabel_id: favoritelabel_id,
      create_new_cart: create_new_cart,
      favorite_product_ids: "",
      // container_type: ''
    };
    AddFavoriteAddToCartFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res && res.message) {
          this.createSuccessMsg("success", res.data.message);
          this.FetchFavoriteLevelList(this.state.activePage, this.state.limit);
          this.FetchCartItemList();
        } else {
          this.createSuccessMsg("error", "Somethink issue in cart");
        }
      }
    );
  };

  removeFromfavouriteList = (favoritelabel_id) => {
    let formValues = {
      favoritelabel_id: favoritelabel_id,
    };
    RemoveFavouriteList(formValues, this.props.dispatch).then((res) => {
      if (res.data && res.data.message) {
        this.createSuccessMsg("success", res.data.message);
        this.FetchFavoriteLevelList(this.state.activePage, this.state.limit);
        this.FetchCartItemList();
      }
    });
  };

  createSuccessMsg = (type, msg) => {
    if (type === "error") {
      if (msg && msg.quantity && msg.quantity[0]) {
        sweetAlertBox("Attention!!", msg.quantity[0], "info");

        // this.setState({
        //   error: msg.quantity[0],
        //   success: false,
        // });
      } else {
        sweetAlertBox("Error!!", msg, "error");
        // this.setState({
        //   error: msg,
        //   success: false,
        // });
      }
    } else {
      sweetAlertBox("Success!!", msg, "success", 2000, false);
      // this.setState({
      //   error: false,
      //   success: msg,
      // });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      error: false,
      success: false,
      addressOpne: false,
    });
  };

  deleteFavourite = (value) => {
    if (value === "agree") {
      this.removeFromfavouriteList(this.state.favourite_id);
      this.setState({ openDialog: false });
    }
  };
  openDialogForRemove = (favoritelabel_id) => {
    this.setState({ openDialog: true, favourite_id: favoritelabel_id });
  };

  handleCloseOpnDialog = () => {
    this.setState({ openDialog: false });
  };

  redicrectToshopping = (favouriteLavelId, favouriteLevelName) => {
    this.props.history.push({
      pathname: "/shoppinglist",
      state: {
        favourite_id: favouriteLavelId,
        favourite_name: favouriteLevelName,
      },
    });
  };

  UpdateCartItemFunctionlity = (
    product_id,
    added_quantity,
    is_case,
    note,
    container_type
  ) => {
    let formValue = {
      product_id: product_id,
      quantity: added_quantity,
      is_case: is_case,
      note: note ? note : "",
      container_type: container_type ? container_type : "",
    };
    let favoriteCartItemList = this.state.favoriteCartItemList;
    let SubTotal = 0;
    this.setState({ disabled: true });
    UpdateCartFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.errors && res.errors.status === 400 && res.errors.data) {
        this.createSuccessMsg("error", res.errors.data.errors);
      } else {
        for (let i = 0; i < favoriteCartItemList.length; i++) {
          if (favoriteCartItemList[i].product_id === product_id) {
            favoriteCartItemList[i].total = res.subtotal;
          }
          SubTotal += Number(favoriteCartItemList[i].total);
        }
        this.FetchCartItemList();
      }
      this.setState({
        disabled: false,
        favoriteCartItemList,
        SubTotal: SubTotal,
      });
    });
  };

  deleteCartItem = (product_id, added_quantity, is_case, container_type) => {
    let formValue = {
      product_id: product_id,
      is_case: is_case,
      container_type: container_type,
    };
    if (isNaN(added_quantity)) {
      added_quantity = 0;
    }
    DeleteCrtIemFunctionlity(formValue, this.props.dispatch).then((res) => {
      if (res.data) {
        this.FetchCartItemList();
      }
    });
  };

  AddCountForFavouritecartList = (
    product_id,
    added_quantity,
    added_qty_is_case,
    icons,
    index,
    container_type
  ) => {
    let SubTotal = 0;
    let favoriteCartItemList = this.state.favoriteCartItemList;

    if (icons === "icon-plus") {
      for (let i = 0; i < favoriteCartItemList.length; i++) {
        if (favoriteCartItemList[i].id === index) {
          favoriteCartItemList[i].qty = favoriteCartItemList[i].qty + 1;
          this.UpdateCartItemFunctionlity(
            product_id,
            favoriteCartItemList[i].qty,
            favoriteCartItemList[i].is_case,
            favoriteCartItemList[i].note,
            favoriteCartItemList[i].container_type
          );
        }
      }
    } else if (icons === "icon-minus") {
      for (let i = 0; i < favoriteCartItemList.length; i++) {
        if (favoriteCartItemList[i].id === index) {
          favoriteCartItemList[i].qty = favoriteCartItemList[i].qty - 1;
          if (added_quantity > 1) {
            this.UpdateCartItemFunctionlity(
              product_id,
              favoriteCartItemList[i].qty,
              favoriteCartItemList[i].is_case,
              favoriteCartItemList[i].note,
              favoriteCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              favoriteCartItemList[i].qty,
              favoriteCartItemList[i].is_case,
              favoriteCartItemList[i].container_type
            );
          }
        }
      }
    } else {
      for (let i = 0; i < favoriteCartItemList.length; i++) {
        if (favoriteCartItemList[i].id === index) {
          favoriteCartItemList[i].qty = added_quantity
            ? added_quantity
            : favoriteCartItemList[i].qty;
          if (added_quantity === "") {
            this.setState({ headerinputField: true });
            var el = document.getElementById(`${product_id}`);
            el.value = "";
            var event = new Event("input", { target: el, bubbles: true });
            return el ? el.dispatchEvent(event) : false;
          }
          added_quantity = parseInt(added_quantity);
          if (added_quantity > 0) {
            this.UpdateCartItemFunctionlity(
              product_id,
              favoriteCartItemList[i].qty,
              favoriteCartItemList[i].is_case,
              favoriteCartItemList[i].note,
              favoriteCartItemList[i].container_type
            );
          } else {
            this.deleteCartItem(
              product_id,
              favoriteCartItemList[i].qty,
              favoriteCartItemList[i].is_case,
              favoriteCartItemList[i].container_type
            );
          }
        }
      }
    }
    this.setState({
      favoriteCartItemList: favoriteCartItemList,
      headerinputField: false,
    });
  };

  changeheaderinputFieldValue = () => {
    this.setState({ headerinputField: false });
  };

  OpenDialog = () => {
    this.setState({ setOpen: true });
  };

  changeLevelValue = (e) => {
    this.setState({ favoriteLevelName: e.target.value });
  };

  onClickSaveFaouriteLevel = () => {
    let favoriteLevelName = this.state.favoriteLevelName;
    if (favoriteLevelName !== "") {
      this.addFavoiteLevelFunctionlity(favoriteLevelName);
    }
  };

  addFavoiteLevelFunctionlity = (favoriteLevel) => {
    let formValues = {
      name: favoriteLevel,
    };
    AddFavoriteLevelFunctionlity(formValues, this.props.dispatch).then(
      (res) => {
        if (res) {
          console.log("res", res);
          this.FetchFavoriteLevelList(this.state.activePage, this.state.limit);
          this.setState({ setOpen: false });
        }
      }
    );
  };
  CloseDialog = () => {
    this.setState({ setOpen: false });
  };

  SetBodyClass = (value) => {
    this.setState({ bodyInnerClass: value });
  };

  commonSetParentIdsOnlyIdLevel = (category_id, level) => {
    let selectedIndexForparentIds = this.state.parent_Ids.indexOf(category_id);
    let selectOnlyIndexForparentOnlyId =
      this.state.parent_onlyid.indexOf(category_id);
    let selectParentLevelIndexParentLevel =
      this.state.parent_level.indexOf(level);
    var reultParentOnlyId = CommonSetParentOnlyIdLevel(
      selectOnlyIndexForparentOnlyId,
      this.state.parent_onlyid,
      category_id
    );
    var resultParentLevel = CommonSetParentLevelSet(
      selectParentLevelIndexParentLevel,
      this.state.parent_level,
      level
    );
    var resultParentIds = CommonSetParentIds(
      selectedIndexForparentIds,
      this.state.parent_Ids,
      category_id,
      level
    );
    this.setState({
      parent_Ids: resultParentIds,
      parent_onlyid: reultParentOnlyId,
      parent_level: resultParentLevel,
    });
  };

  FetchSubCategoryList = (category_id, level) => {
    let formValues = {
      ids: category_id,
      limit: 100,
    };
    this.commonSetParentIdsOnlyIdLevel(category_id, level);
    this.setState({ subCategoryLoader: true });
    SubCategeriesListView(formValues, this.props.dispatch).then((res) => {
      let subCategoryList = [];
      let SubSubCategoryDetails = [];
      if (res.data && res.data.result) {
        if (level === 0) {
          subCategoryList = res.data.result;
        } else {
          SubSubCategoryDetails = res.data.result;
        }
      } else if (res.errors && res.errors._error.status === 401) {
        const refreshToken = JSON.parse(localStorage.getItem("access_token"));
        let myThis = this;
        if (refreshToken) {
          this.RefreshApiToken(
            refreshToken.refresh_token,
            myThis,
            function (myThis) {
              myThis.FetchSubCategoryList(formValues.ids, level);
            }
          );
        }
      }
      this.setState({
        SubCategoryDetails: subCategoryList,
        SubSubCategoryDetails: SubSubCategoryDetails,
        subCategoryLoader: false,
      });
    });
  };

  handleInnerOpenDialog(e, subcategory_name, has_child, level, parent_id) {
    if (has_child === true) {
      this.setState({ open_inner: true, SubcategoryName: subcategory_name });
      this.FetchSubCategoryList(e, level);
    } else {
      this.props.history.push({
        pathname: "/listing/" + e,
        state: {
          category_id: e,
          parent_id: parent_id,
          parent_Ids: this.state.parent_Ids,
          parent_onlyid: this.state.parent_onlyid,
          parent_level: this.state.parent_level,
        },
      });
    }
  }

  handleInnerCloseDialogFav() {
    this.setState({ open_inner: false });
  }

  handleDelete = (id) => {
    this.setState({ favourite_id: id, openDialog: true });
  };
  render() {
    const {
      CategoryDetails,
      setOpen,
      favoriteCartItemList,
      SubTotal,
      CartCount,
      CategoryOption,
      favouriteList,
      success,
      web_customize,
      error,
    } = this.state;
    return (
      <>
        <div
          className="main_containerLoader"
          style={{ display: this.state.MainLoader ? "flex" : "none" }}
        >
          <CustomPreloader />
        </div>
        <ScrollColorTypography component="div" className="main-content">
          <Header
            CategoryDetails={CategoryDetails}
            CategoryOption={CategoryOption}
            cartItemList={favoriteCartItemList}
            handleOpenDialog={this.handleOpenDialogFav}
            pageName={this.state.pageName}
            history={this.props.history}
            CartCount={CartCount}
            cartLoader={this.state.cartLoader}
            FetchCartCount={() => this.FetchCartItemList()}
            SubTotal={SubTotal}
            AddCountForcartList={(
              product_id,
              added_quantity,
              is_case,
              icons,
              index,
              container_type
            ) =>
              this.AddCountForFavouritecartList(
                product_id,
                added_quantity,
                is_case,
                icons,
                index,
                container_type
              )
            }
            changeheaderinputFieldValue={() =>
              this.changeheaderinputFieldValue()
            }
            headerinputField={this.state.headerinputField}
            SetBodyClass={(value) => this.SetBodyClass(value)}
            logo={this.state.logo}
            extraCharges={this.state.extraCharges}
            handleInnerOpenDialog={(
              catId,
              subcategory_name,
              has_child,
              level,
              parent_id
            ) =>
              this.handleInnerOpenDialog(
                catId,
                subcategory_name,
                has_child,
                level,
                parent_id
              )
            }
          />
          <CategoriesModal
            open={this.state.open}
            handleOpenDialog={this.handleOpenDialogFav}
            handleCloseDialog={this.handleCloseDialogFav}
            CategoryDetails={CategoryDetails}
            history={this.props}
          />

          <SubCategoryModal
            open_inner={this.state.open_inner}
            SubcategoryName={this.state.SubcategoryName}
            // handleInnerOpenDialog={this.handleInnerOpenDialog}
            handleInnerCloseDialog={this.handleInnerCloseDialogFav}
            handleCloseDialog={this.handleCloseDialogFav}
            handleOpenDialog={this.handleOpenDialogFav}
            SubCategoryDetails={this.state.SubCategoryDetails}
            history={this.props.history}
            pageName={this.state.pageName}
            SubSubCategoryDetails={this.state.SubSubCategoryDetails}
            subCategoryLoader={this.state.subCategoryLoader}
            FetchSubCategoryList={(id, level) =>
              this.FetchSubCategoryList(id, level)
            }
            parentIds={this.state.parent_Ids}
            parent_onlyid={this.state.parent_onlyid}
            parent_level={this.state.parent_level}
          />
          <div
            className="main-data cart_data"
            style={{
              paddingTop:
                web_customize !== null &&
                web_customize?.top_marquee !== null &&
                web_customize?.top_marquee !== "" &&
                window.screen.availWidth > 900
                  ? "11rem"
                  : "",
            }}
          >
            <section className="user_details user_order">
              <div className="user_main_wrapper">
                <UserDetailsMenu
                  history={this.props.history}
                  mobileViewHide={this.state.mobileViewHide}
                  openDetailsMenu={() => this.openDetailsMenu()}
                  profileDetails={this.state.profileDetails}
                />

                <div
                  className={`${
                    window.screen.availWidth >= 900 ? "w-100" : "rowMobFav"
                  } row`}
                >
                  <div
                    className={`${
                      window.screen.availWidth >= 900 ? "" : "pr-0 "
                    } col-lg-12`}
                  >
                    <div
                      className={
                        this.state.bodyInnerClass === true
                          ? "tbl-main show-slide"
                          : "tbl-main"
                      }
                    >
                      <div class="cust_head_main cust_hide m-0 p-3">
                        <TypographyTextColor Color="heading" component="h4">
                          Favorite
                        </TypographyTextColor>
                        <SecondaryButton
                          variant="contained"
                          className="text-white my-1 mx-1"
                          onClick={() => this.OpenDialog()}
                          Fonttype="other"
                        >
                          Create New Shopping List
                        </SecondaryButton>
                      </div>

                      <div className="table-box">
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className="text-center">
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  {" "}
                                  Action
                                </TypographyTextColor>
                              </TableCell>
                              <TableCell>
                                {" "}
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Name
                                </TypographyTextColor>
                              </TableCell>
                              <TableCell>
                                {" "}
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Items
                                </TypographyTextColor>
                              </TableCell>
                              <TableCell>
                                {" "}
                                <TypographyTextColor
                                  Color="heading"
                                  component="span"
                                >
                                  Creation Date
                                </TypographyTextColor>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {favouriteList && favouriteList.length > 0
                              ? favouriteList.map((row, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        data-label="Action"
                                        className="text-center"
                                      >
                                        <TypographySecondaryColor>
                                          <RemoveRedEyeIcon
                                            onClick={() =>
                                              this.redicrectToshopping(
                                                row.id,
                                                row.name
                                              )
                                            }
                                          />
                                        </TypographySecondaryColor>
                                      </TableCell>
                                      <TableCell
                                        data-label="Name"
                                        onClick={() =>
                                          this.redicrectToshopping(
                                            row.id,
                                            row.name
                                          )
                                        }
                                        className="tname"
                                      >
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {" "}
                                          {row.name}
                                        </TypographyTextColor>
                                      </TableCell>
                                      <TableCell
                                        data-label="Item"
                                        onClick={() =>
                                          this.redicrectToshopping(
                                            row.id,
                                            row.name
                                          )
                                        }
                                      >
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {" "}
                                          {row.items}
                                        </TypographyTextColor>
                                      </TableCell>
                                      <TableCell
                                        data-label="Date"
                                        onClick={() =>
                                          this.redicrectToshopping(
                                            row.id,
                                            row.name
                                          )
                                        }
                                      >
                                        <TypographyTextColor
                                          Color="other"
                                          component="span"
                                        >
                                          {" "}
                                          {this.convertTodate(row.created_date)}
                                        </TypographyTextColor>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              : ""}
                          </TableBody>
                        </Table>
                      </div>

                      <div className="row align-items-center px-3 py-4">
                        <div className="col-lg-6 col-md-12 col-12">
                          <div className="form-group row align-items-center mb-0 gutter-8">
                            <div className="col-sm-12">
                              <TypographyTextColor
                                Color="other"
                                component="span"
                              >
                                {" "}
                                {this.renderShowsTotal()}
                              </TypographyTextColor>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                          <nav aria-label="Page navigation">
                            <Pagination
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.limit}
                              totalItemsCount={this.state.totalSize}
                              pageRangeDisplayed={10}
                              onChange={this.handlePageChange.bind(this)}
                              itemClass="page-item"
                              linkClass="page-link"
                              prevPageText="Prev"
                              firstPageText="First"
                              lastPageText="Last"
                              nextPageText="Next"
                            />
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {success ? (
                <Snackbar
                  open={this.state.success}
                  onClose={this.handleClose}
                  autoHideDuration={6000}
                  className="success"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  action={
                    <React.Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={this.handleClose}
                      >
                        <i className="icon-close"></i>
                      </IconButton>
                    </React.Fragment>
                  }
                  message={
                    <React.Fragment>
                      <div className="row gutter-8">
                        <div className="col-auto">
                          <i className="icon-closer"></i>
                        </div>
                        <div className="col">
                          <p>{success}</p>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                />
              ) : (
                ""
              )}
              {error ? (
                <Snackbar
                  open={this.state.error}
                  onClose={this.handleClose}
                  autoHideDuration={6000}
                  className="error"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  action={
                    <React.Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={this.handleClose}
                      >
                        <i className="icon-close"></i>
                      </IconButton>
                    </React.Fragment>
                  }
                  message={
                    <React.Fragment>
                      <div className="row gutter-8">
                        <div className="col-auto">
                          <i className="icon-closer"></i>
                        </div>
                        <div className="col">
                          <p>{error}</p>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                />
              ) : (
                ""
              )}

              <Dialog
                open={this.state.openDialog}
                onClose={() => this.handleCloseOpnDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure want to delete this favourite item?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.handleCloseOpnDialog()}
                    color="primary"
                  >
                    Disagree
                  </Button>
                  <Button
                    onClick={() => this.deleteFavourite("agree")}
                    color="primary"
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={setOpen}
                onClose={() => this.CloseDialog()}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                className="popUp"
              >
                <DialogContent className="p-0">
                  <div className="popup">
                    <div className="header px-2 py-2">
                      Add Level
                      <span className="float-right">
                        <a
                          href="javascript:void(0);"
                          onClick={() => this.CloseDialog()}
                        >
                          <span class="icon-close"></span>
                        </a>
                      </span>
                    </div>
                    <div className="boxshadow rounded-0 py-3 px-3">
                      <div className="form-group mb-4">
                        <div className="content">
                          <form>
                            <div class="form-group d-flex align-items-center">
                              <TextField
                                id="outlined-textarea"
                                placeholder="New List"
                                multiline
                                variant="outlined"
                                onChange={(event) =>
                                  this.changeLevelValue(event)
                                }
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="popbtncancel"
                    onClick={() => this.CloseDialog()}
                  >
                    Cancel
                  </Button>
                  <SecondaryButton
                    className="popbtn"
                    onClick={() => this.onClickSaveFaouriteLevel()}
                  >
                    Add
                  </SecondaryButton>
                </DialogActions>
              </Dialog>
            </section>
            <Footer />
          </div>
        </ScrollColorTypography>
      </>
    );
  }
}

export default FavoriteList;
